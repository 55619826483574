import { h, FunctionalComponent, Fragment } from "preact";

import IntercomMessenger from "components/shared/IntercomMessenger";
import BrazeConfiguration from "components/shared/BrazeConfiguration";
import { useCurrentUrl } from "hooks/currentUrlHook";
import RedditConfiguration from "components/shared/RedditConfiguration";
import ProsperStackConfiguration from "components/shared/ProsperStackConfiguration";

const ExternalServices: FunctionalComponent = () => {
  const { currentUrl } = useCurrentUrl();

  return (
    <Fragment>
      {!currentUrl?.includes("/signup") && <IntercomMessenger />}
      <BrazeConfiguration />
      <RedditConfiguration />
      <ProsperStackConfiguration />
    </Fragment>
  );
};

export default ExternalServices;
