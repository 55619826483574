import useUser from "hooks/userHook";
import { FC, useEffect } from "react";
import { prosperStackClientId as clientId } from "config";
import { useAppProps } from "hooks/appPropHook";

declare global {
  interface Window {
    ProsperStack: any;
  }
}

export const getProsperStack = () =>
  typeof window !== "undefined" ? window?.ProsperStack : undefined;

function loadProsperStack(callback: () => void) {
  if (typeof window !== "undefined") {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://cdn.prosperstack.com/convert/latest/convert.min.js";

    const first = document.getElementsByTagName("script")[0];
    first?.parentNode?.insertBefore(script, first);

    script.onload = () => {
      window.ProsperStack = window.ProsperStack || {};
      window.ProsperStack.clientId = clientId;
      callback();
    };

    document.body.appendChild(script);
  }
}

const ProsperStackConfiguration: FC = () => {
  const { user } = useUser();

  const initProsperStack = () => {
    if (getProsperStack()) {
      window?.ProsperStack?.convert.init({
        subscription: {
          platformId: user?.subscription?.uuid,
        },
      });
    } else {
      loadProsperStack(() => {
        window?.ProsperStack?.convert.init({
          subscription: {
            platformId: user?.subscription?.uuid,
          },
        });
      });
    }
  };

  const { prosperStackConvertEnabled } = useAppProps();
  useEffect(() => {
    if (prosperStackConvertEnabled && user && user?.subscription) {
      initProsperStack();
    }
  }, [user]);

  return null;
};

export default ProsperStackConfiguration;
