export enum AppPropKeys {
  mobileAppNotice = "mobile_app_notice",
  traceEnabled = "trace_enabled",
  speEnabled = "spe_enabled",
  specialPromo = "special_promo",
  orgSizeLimit = "org_size_limit",
  prosperStackConvertEnabled = "prosper_stack_convert_enabled",
}

export interface AppProps {
  mobileAppNotice?: boolean;
  traceEnabled?: boolean;
  orgSizeLimit?: string;
  // use Stripe Payment Elements/setup intent
  speEnabled?: boolean;
  specialPromo?: string;
  prosperStackConvertEnabled?: boolean;
}
