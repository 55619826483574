import Colors from "style/colors";
import { styled } from "linaria/lib/react";
import mq from "style/breakpoints";

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10005;
  width: 343px;
  height: auto;
  max-height: max-content;
  background: ${Colors.bgLight2};
  margin: auto;
  border-radius: 16px;

  ${mq.minSm} {
    width: 636px;
  }
`;

export const Close = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;

  span {
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px !important;
  border-top: 1px dashed ${Colors.bgGrey2} !important;
  margin: 16px 0 !important;
  padding: 0 !important;
`;

export const Warning = styled.div<{ showContactOurTeam: boolean }>`
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 192, 67, 0.15);
  height: 72px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  margin-bottom: ${({ showContactOurTeam }) =>
    showContactOurTeam ? "16px" : "48px"};

  > h3 {
    color: #966a13;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-transform: inherit;
  }

  svg {
    width: 30px;
  }
`;

export const ContactOurTeam = styled.div`
  width: 100%;
  border-radius: 8px;
  background: rgba(225, 25, 0, 0.15);
  height: 72px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  margin-bottom: 48px;

  > h3 {
    color: rgba(225, 25, 0, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-transform: inherit;

    span {
      color: rgba(225, 25, 0, 1);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  svg {
    width: 30px;
  }
`;

export const BillingUpdateContainer = styled.div`
  width: 100%;
`;

export const BillingUpdateContent = styled.div`
  width: 100%;
  height: 240px;
  border-radius: 16px;
  background: #eeeeee;
  padding: 40px;

  h3 {
    margin-bottom: 24px;
    color: #000000;
    text-align: center;
    font-family: "Neusa Next Pro";
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const BillingUpdateButtons = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button {
    width: 100%;
    height: 56px !important;
    padding: 16px 32px !important;

    > div span {
      font-size: 18px !important;
      font-weight: 600 !important;
      line-height: 24px !important;
    }
  }

  button:nth-child(1) {
    color: #ffffff;
  }

  button:nth-child(2) {
    background: transparent;
    color: #000000;

    :hover:active {
      background-color: transparent !important;
    }
  }
`;

export const PriceContainer = styled.div`
  width: 202px;
  height: 110px;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    color: #000000;
    text-align: center;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  span {
    color: #8e8e93;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
  }
`;

export const ArrowPrice = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 99px;
  background: #0d41eb;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-Arrow--Right {
    color: #ffffff;
    display: flex;
  }
`;

export const BackBillingUpdate = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 99px;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 24px;

  .icon-Angle--Left {
    display: flex;
    font-weight: 600;
  }
`;
